import Web3 from 'web3';
import mydidContractABI from '../../assets/mydidContractABI.json';
import createKeccakHash from 'keccak';
import bs58 from 'bs58';
import { toChecksumAddress } from 'ethereum-checksum-address';
import { base58btc } from 'multiformats/bases/base58';
import secp256k1 from 'secp256k1';
import bluebirdPromise from 'bluebird';

const web3 = new Web3(process.env.VUE_APP_WEB3_PROVIDER);

const mydidContract = new web3.eth.Contract(
  mydidContractABI,
  process.env.VUE_APP_MYDID_CONTRACT_ADDR
);

export function templateTypeToLabel(type, i18n, short) {
  switch (type) {
    case 'Basic':
      return 'Certified';
    case 'Community':
      return 'Peer-to-peer';
    case 'Participation':
      return 'Participation';
    case 'Membership':
      return 'Community';
    case 'Ticket':
      return 'Ticket';
    case 'Role':
      return 'Role';
  }
}

export async function getTemplateList(addr, type) {
  const categoryTypes = [
    'Basic',
    'Community',
    'Participation',
    'Membership',
    'Role',
  ];

  const category = categoryTypes.indexOf(type);
  if (category === -1) throw new Error('Bad type for template list');

  const response = (
    await mydidContract.methods.getIssuerTemplates(addr, category).call()
  )
    .slice()
    .reverse();

  return response.filter(
    (template) =>
      template.templateHash !==
      '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
  );
}

export async function getTemplateListData(templateList) {
  const CONCURRENCY_LIMIT = 6;

  let templates = await bluebirdPromise.map(
    templateList,
    async (template) => {
      try {
        const data = await getJsonDataFromCID(hashToCID(template.templateHash));
        return {
          templateHash: template.templateHash,
          name: template.name,
          status: Number(template.status),
          index: Number(template.index),
          data,
        };
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    { concurrency: CONCURRENCY_LIMIT }
  );

  templates = templates.filter((el) => el !== null);
  return templates;
}

export function didToAddress(did) {
  const didValue = did.split(':')[2];
  try {
    const publicKeyUintArray = base58btc.decode(didValue);
    const compressedPublicKey = Buffer.from(publicKeyUintArray).toString('hex');
    const decompressedBuffer = secp256k1.publicKeyConvert(
      Buffer.from(compressedPublicKey, 'hex'),
      false
    );
    const hash = createKeccakHash('keccak256')
      .update(Buffer.from(decompressedBuffer).slice(1))
      .digest();
    const address = toChecksumAddress(hash.slice(-20).toString('hex'));
    return address;
  } catch (e) {
    // console.log(e);
    return didValue;
  }
}

export async function getJsonDataFromUrl(url) {
  if (
    !url.startsWith('https://myntfsid.mypinata.cloud/') &&
    !url.startsWith('https://resolver.mydid.eu/')
  )
    throw 'Bad URL input for method : getJsonDataFromUrl';
  return new Promise((resolve, reject) => {
    fetch(url)
      .then(function (response) {
        return response.arrayBuffer();
      })
      .then(function (buffer) {
        const decoder = new TextDecoder('utf-8');
        const text = decoder.decode(buffer);
        resolve(JSON.parse(text));
      })
      .catch((err) => {
        if (!url.startsWith('https://myntfsid.mypinata.cloud/')) reject(err);

        // retry with secondary ipfs gateway
        fetch(
          url.replace('https://myntfsid.mypinata.cloud/', 'https://ipfs.io/')
        )
          .then(function (response) {
            return response.arrayBuffer();
          })
          .then(function (buffer) {
            const decoder = new TextDecoder('utf-8');
            const text = decoder.decode(buffer);
            resolve(JSON.parse(text));
          })
          .catch((err) => {
            reject(err);
          });
      });
  });
}

export async function getJsonDataFromCID(cid) {
  return new Promise((resolve, reject) => {
    fetch('https://myntfsid.mypinata.cloud/ipfs/' + cid)
      .then(function (response) {
        return response.arrayBuffer();
      })
      .then(function (buffer) {
        const decoder = new TextDecoder('utf-8');
        const text = decoder.decode(buffer);
        resolve(JSON.parse(text));
      })
      .catch((err) => {
        // retry with secondary ipfs gateway
        fetch('https://myntfsid.mypinata.cloud/ipfs/' + cid)
          .then(function (response) {
            return response.arrayBuffer();
          })
          .then(function (buffer) {
            const decoder = new TextDecoder('utf-8');
            const text = decoder.decode(buffer);
            resolve(JSON.parse(text));
          })
          .catch((err) => {
            reject(err);
          });
      });
  });
}

export function hashToCID(hash) {
  const cleanHash = (hash + '').replace('0x', '');
  const bytes = Buffer.from('1220' + cleanHash, 'hex');
  const cid = bs58.encode(bytes);
  return cid;
}

export function getUrlFromCID(cid) {
  return 'https://myntfsid.mypinata.cloud/ipfs/' + cid;
}

const getMemberCardsList = async () => {
  return await mydidContract.methods.getMemberCards().call();
};

function removeLeadingZerosAndConvertToASCII(hexString) {
  const cleanedString = hexString.startsWith('0x')
    ? hexString.slice(2)
    : hexString;

  const firstNonZeroIndex =
    cleanedString.search(/[^0]/) !== -1
      ? cleanedString.search(/[^0]/)
      : cleanedString.length;

  const hexWithoutLeadingZeros = cleanedString.slice(firstNonZeroIndex);
  return hex2ascii(hexWithoutLeadingZeros);
}

function hex2ascii(hexString) {
  let asciiString = '';
  for (let i = 0; i < hexString.length; i += 2) {
    const hexPair = hexString.slice(i, i + 2);
    asciiString += String.fromCharCode(parseInt(hexPair, 16));
  }
  return asciiString;
}

const getTemplateHistories = async (templateHash) => {
  const templateHistory = await mydidContract.methods
    .templateHistories(templateHash)
    .call();
  return templateHistory;
};

const getOldestTemplateHash = async (initialTemplateHash) => {
  let currentTemplateHash = initialTemplateHash;

  while (currentTemplateHash) {
    try {
      const templateHistory = await getTemplateHistories(currentTemplateHash);

      if (
        templateHistory ==
        '0x0000000000000000000000000000000000000000000000000000000000000000'
      )
        break;

      currentTemplateHash = templateHistory;
    } catch (e) {
      console.error('Error while retrieving template history', e);
      break;
    }
  }

  return currentTemplateHash;
};

const getTemplateData = async (templateHash) => {
  try {
    return await getJsonDataFromCID(hashToCID(templateHash));
  } catch (error) {
    console.error("Erreur lors de l'appel de getTemplateData :", error);
    return null;
  }
};

export async function getDID(addr) {
  return await mydidContract.methods.getDID(addr).call();
}

export async function isIssuer(addr) {
  return await mydidContract.methods
    .hasRole(createKeccakHash('keccak256').update('ISSUER_ROLE').digest(), addr)
    .call();
}

export async function getIssuerCategory(addr) {
  return await mydidContract.methods.issuerCategory(addr).call();
}

export default {
  async install(app) {
    const utils = {
      getJsonDataFromUrl,
      getTemplateList,
      didToAddress,
      getTemplateListData,
      getMemberCardsList,
      removeLeadingZerosAndConvertToASCII,
      getOldestTemplateHash,
      getTemplateData,
      isIssuer,
      getIssuerCategory,
      templateTypeToLabel,
    };
    app.provide('utils', utils);
  },
};
