<template>
  <div :class="{ 'opacity-50 relative': templateContractState == 'none' }">
    <div
      @click.stop="$emit('click')"
      class="cursor-pointer relative w-full h-full"
    >
      <div
        v-if="type == 'Membership'"
        :id="randomDivId"
        class="w-[384px] aspect-[85.60/53.98] rounded-2xl"
        :class="{
          '!w-[240px]': formatSm,
          'border-secondary border-4': enableSelection && selected,
          'border-transparent border-4': enableSelection && !selected,
        }"
      ></div>
      <div
        v-else-if="type == 'Role'"
        :class="{
          'border rounded-2xl pt-2 pb-1': !isRoleNotLarge,
        }"
      >
        <div
          :id="randomDivId"
          class="w-[240px] aspect-square rounded-2xl"
          :class="{
            '!w-[184px]': formatSm,
            'border-secondary border-4': enableSelection && selected,
            'border-transparent border-4': enableSelection && !selected,
          }"
        ></div>
      </div>
      <div
        v-else
        :id="randomDivId"
        class="w-[240px] h-[376px] rounded-2xl"
        :class="{
          '!w-[184px] !h-[288px]': formatSm,
          'border-secondary border-4': enableSelection && selected,
          'border-transparent border-4': enableSelection && !selected,
        }"
      ></div>
    </div>
  </div>
</template>

<script>
import { ref, computed, inject, onMounted, watch, onUnmounted } from 'vue';
import templateColors from '../../assets/templateColors.json';

export default {
  props: {
    templateImage: String,
    templateName: String,
    templateStartDate: String,
    templateEndDate: String,
    templateLocation: String,
    templateThemeBgColor: String,
    templateThemeTextColor: String,
    templateThemeEffect: String,
    templateThemeTexture: String,
    templateThemeIsLogoBack: Boolean,
    templateThemeShape: String,
    templateSessions: Array,
    templateContractState: String,
    issuerName: String,
    issuerImage: String,
    type: String,
    formatSm: Boolean,
    enableSelection: Boolean,
    selected: Boolean,
    is3d: Boolean,
    isRoleNotLarge: Boolean,
  },
  setup(props) {
    const utils = inject('utils');

    const randomDivId = ref(Math.random().toString(36).substring(2, 15));
    const viewId = ref(null);

    const templateLabel = ref(
      utils.templateTypeToLabel(props.type, 'en', true)
    );

    const ongoingSessions = computed(() => {
      if (!props.templateSessions) return null;

      return props.templateSessions.filter((session) => {
        if (session.didLimit != 1) return false;
        return session.didHistory.length == 0;
      }).length;
    });
    const deliveredBadges = computed(() => {
      if (!props.templateSessions) return null;

      return props.templateSessions.reduce((sum, session) => {
        return sum + session.didHistory.length;
      }, 0);
    });

    const issuerDisplayedName = computed(() => {
      return props.issuerName.length >= 20
        ? props.issuerName.substring(0, 17) + '...'
        : props.issuerName;
    });

    const flutterConfig = computed(() => {
      return JSON.stringify({
        type: props.type.toLowerCase(),
        category: 'template',
        display:
          props.type != 'Role'
            ? {
                shaded: false,
                flat: !props.is3d,
                hasFloatingAnimation: false,
                hasInitialAnimation: false,
                padding: props.is3d ? 10 : 0,
              }
            : {
                isRoleLarge: !props.isRoleNotLarge,
              },
        theme:
          props.type != 'Role'
            ? {
                backgroundCardColor: props.templateThemeBgColor
                  ? props.templateThemeBgColor
                  : templateColors[props.type].backgroundCardColor,
                textColor: props.templateThemeTextColor
                  ? props.templateThemeTextColor
                  : templateColors[props.type].textColor,
                cardEffect: props.templateThemeEffect
                  ? props.templateThemeEffect
                  : 'glare',
                textureImageURL: props.templateThemeTexture
                  ? props.templateThemeTexture
                  : '',
                isLogoBack: props.templateThemeIsLogoBack
                  ? props.templateThemeIsLogoBack
                  : false,
              }
            : {
                shape: props.templateThemeShape,
                backgroundCardColor: props.templateThemeBgColor,
                textColor: templateColors[props.type].textColor,
              },
        data:
          props.type != 'Role'
            ? {
                name: props.templateName,
                issuerName: props.issuerName,
                issuerImageURL: props.issuerImage,
                stats: props.templateSessions
                  ? {
                      owner: deliveredBadges.value,
                      pending: ongoingSessions.value,
                    }
                  : null,
                imageURL: props.templateImage,
                issuerLogoURL: props.issuerImage,
                ...(['Participation', 'Ticket'].includes(props.type)
                  ? {
                      location: props.templateLocation
                        ? props.templateLocation
                        : '',
                    }
                  : {}),
                ...(['Ticket'].includes(props.type)
                  ? {
                      startDate: props.templateStartDate
                        ? new Date(props.templateStartDate).toISOString()
                        : new Date().toISOString(),
                      endDate: props.templateEndDate
                        ? new Date(props.templateEndDate).toISOString()
                        : new Date().toISOString(),
                    }
                  : {}),
              }
            : {
                name: props.templateName,
                issuerName: props.issuerName,
                issuerImageURL: props.issuerImage,
                stats: props.templateSessions
                  ? {
                      owner: deliveredBadges.value,
                      pending: ongoingSessions.value,
                    }
                  : null,
                imageURL: props.templateImage,
              },
      });
    });

    const addFlutterView = () => {
      viewId.value = flutterApp.addView({
        hostElement: document.getElementById(randomDivId.value),
        initialData: {
          lang: 'en',
          padding: props.is3d ? 10 : 0,
          config: flutterConfig.value,
        },
      });
    };

    const updateFlutterView = () => {
      if (window[`updateCardConfiguration_${viewId.value}`]) {
        window[`updateCardConfiguration_${viewId.value}`](flutterConfig.value);
      }
    };

    const removeFlutterView = () => {
      flutterApp.removeView(viewId.value);
    };

    const flipTemplate = () => {
      if (window[`flip_${viewId.value}`]) {
        window[`flip_${viewId.value}`](JSON.stringify({}));
      }
    };

    const getTemplateFlip = () =>
      new Promise((resolve) => {
        if (window[`getRotation_${viewId.value}`]) {
          const listener = (event) => {
            window.removeEventListener(`getRotation_${viewId.value}`, listener);
            const eventData = JSON.parse(event.detail);
            resolve(eventData.data);
          };

          window.addEventListener(`getRotation_${viewId.value}`, listener);

          window[`getRotation_${viewId.value}`](JSON.stringify({}));
        }
      });

    const getTemplateImage = () =>
      new Promise((resolve) => {
        if (window[`exportImage_${viewId.value}`]) {
          const listener = (event) => {
            window.removeEventListener(`exportImage_${viewId.value}`, listener);
            const eventData = JSON.parse(event.detail);
            resolve(eventData.data);
          };

          window.addEventListener(`exportImage_${viewId.value}`, listener);

          window[`exportImage_${viewId.value}`](
            JSON.stringify({
              width: 240 * 2,
              height: 376 * 2,
            })
          );
        }
      });

    onMounted(addFlutterView);
    onUnmounted(removeFlutterView);

    watch(
      () => props,
      () => {
        updateFlutterView();
      },
      { deep: true }
    );

    watch(
      () => flutterConfig.value,
      () => {
        console.log('flutterConfig.value', flutterConfig.value);
      }
    );

    return {
      templateLabel,
      issuerDisplayedName,
      ongoingSessions,
      deliveredBadges,
      randomDivId,
      flutterConfig,
      getTemplateImage,
      getTemplateFlip,
      flipTemplate,
    };
  },
  emits: ['click'],
};
</script>
