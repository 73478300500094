<template>
  <div class="">
    <div
      class="flex flex-col gap-6 sm:gap-8 lg:flex-row lg:gap-10 p-2 sm:p-4 lg:p-5 2xl:p-10 w-full"
    >
      <div class="flex-none">
        <TemplatePreview
          :templateImage="verifiableCredential.image"
          :templateName="verifiableCredential.name"
          :templateLocation="
            verifiableCredential.type === 'Participation'
              ? verifiableCredential.location.split(', ')[0] || 'Virtual'
              : null
          "
          :templateStartDate="
            verifiableCredential.type === 'Participation'
              ? verifiableCredential.startDate
              : null
          "
          :templateEndDate="
            verifiableCredential.type === 'Participation'
              ? verifiableCredential.endDate
              : null
          "
          :templateThemeBgColor="
            verifiableCredential.badgeTheme
              ? verifiableCredential.badgeTheme.backgroundCardColor
              : null
          "
          :templateThemeTextColor="
            verifiableCredential.badgeTheme
              ? verifiableCredential.badgeTheme.textColor
              : null
          "
          :templateThemeEffect="
            verifiableCredential.badgeTheme
              ? verifiableCredential.badgeTheme.cardEffect
              : null
          "
          :templateThemeTexture="
            verifiableCredential.badgeTheme
              ? verifiableCredential.badgeTheme.textureImageURL
              : null
          "
          :templateThemeIsLogoBack="
            verifiableCredential.badgeTheme
              ? verifiableCredential.badgeTheme.isLogoBack
              : null
          "
          :templateThemeShape="
            verifiableCredential.badgeTheme
              ? verifiableCredential.badgeTheme.shape
              : null
          "
          :issuerName="
            verifiableCredential.type === 'Community'
              ? verifiableCredential.templateIssuer.name
              : verifiableCredential.issuer.name
          "
          :issuerImage="
            verifiableCredential.type === 'Community'
              ? verifiableCredential.templateIssuer.profileImage
              : verifiableCredential.issuer.profileImage
          "
          :formatSm="false"
          :type="verifiableCredential.type"
          :is3d="true"
        />
      </div>
      <div class="grow">
        <!-- Certified and community badge membership -->
        <div>
          <div class="flex flex-row mb-5">
            <div class="flex items-center space-x-2 text-gray-400">
              <p class="text-[14px] md:text-[15px] w-[130px]">Badge Type:</p>
            </div>
            <div
              class="flex-row flex justify-center items-center text-start text-[15px] font-medium"
            >
              <p class="text-start text-[14px] md:text-[15px] font-medium">
                {{ templateTypeToLabel(verifiableCredential.type) }}
              </p>
              <div
                v-if="
                  verifiableCredential.type === 'Community' &&
                  verifiableCredential.endorsement
                "
                class="bg-green-100 border-green-300 text-green-500 border m-1 flex flex-row justify-center items-center py-[2px px-1 rounded-lg"
              >
                <icon name="star" class="fill-green-500 w-[12px]" />
                <p class="text-[10px] md:text-[10px]">Approved</p>
              </div>
            </div>
          </div>

          <div class="flex flex-row mb-5">
            <div class="flex items-center space-x-2 text-gray-400">
              <p class="text-[14px] md:text-[15px] w-[130px]">Badge Name:</p>
            </div>
            <div class="flex-col text-start text-[15px] font-medium">
              <p class="text-[14px] md:text-[15px] font-medium">
                {{ verifiableCredential.name }}
              </p>
            </div>
          </div>

          <div class="flex flex-row mb-5">
            <div class="flex items-center space-x-2 text-gray-400">
              <p class="text-[14px] md:text-[15px] w-[130px]">Badge Link:</p>
            </div>
            <div
              class="flex-row flex space-x-2 text-start text-[15px] font-medium"
            >
              <p
                class="text-[12px] md:text-[15px] font-medium italic hidden md:block overflow-hidden"
              >
                {{ code.slice(0, 11) + '...' + code.slice(50) }}
              </p>
              <button @click.stop="copyCode" class="cursor-pointer">
                <img
                  :src="require(`/assets/images/copie.png`)"
                  class="w-[15px] h-[15px] max-w-none z-50"
                />
              </button>
            </div>
          </div>

          <div class="flex flex-row mb-5">
            <div class="flex items-center space-x-2 text-gray-400">
              <p class="text-[14px] md:text-[15px] w-[130px]">Status:</p>
            </div>
            <div
              class="flex-col text-start text-[12px] md:text-[15px] font-medium"
            >
              <div
                v-if="
                  verifiableCredential.expirationDate &&
                  new Date(verifiableCredential.expirationDate) < new Date()
                "
                class="ml-auto"
              >
                <p
                  class="bg-red-100 border-red-300 text-red-500 border py-[1px] px-1 rounded-lg"
                >
                  <icon
                    name="triangle-exclamation"
                    class="fill-red-500 w-[12px]"
                  />
                  <span class="text-[11px] md:text-[11px]">invalid</span>
                </p>
              </div>
              <div v-else class="  ">
                <div
                  class="bg-green-100 border-green-300 text-green-500 border py-[2px] px-1 flex flex-row justify-center items-center rounded-lg"
                >
                  <icon name="wavy-check" class="fill-green-500 w-[14px]" />
                  <span class="text-[11px] md:text-[11px]">Valid</span>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-row mb-5">
            <div class="flex items-center space-x-2 text-gray-400">
              <p class="text-[14px] md:text-[15px] w-[130px]">Time:</p>
            </div>
            <div
              class="text-start text-[15px] flex flex-col md:flex-row gap-2 font-medium"
            >
              <p class="text-[14px] md:text-[15px] font-medium">
                {{ formatDateWithRelativeTime(verifiableCredential.created) }}
              </p>

              <div
                v-if="
                  Math.abs(
                    new Date(vc.timestamp * 1000) -
                      new Date(verifiableCredential.created)
                  ) /
                    1000 <
                  120
                "
                class=""
              >
                <div
                  class="bg-green-100 border-green-300 text-green-500 border w-1/2 lg:w-full py-[2px] px-[2px] flex flex-row justify-center items-center rounded-lg"
                >
                  <icon name="clock" class="fill-green-500 w-[14px]" />
                  <span class="text-[11px] md:text-[11px]">Time-stamped</span>
                </div>
              </div>
            </div>
          </div>

          <!--  If Community and role -->
          <div class="flex flex-row py-5 border-b-[0.2px] border-t-[0.2px]">
            <div
              class="flex items-center lg:space-x-2 space-x-0 w-[130px] text-gray-400"
            >
              <p v-if="verifiableCredential.type !== 'Community'" class="">
                Community:
              </p>
              <p v-else class="">Sender:</p>
            </div>
          
            <a v-if="verifiableCredential.type !== 'Community'"
              :href="
                verifiableCredential.type === 'Community' &&
                communityTemplateData?.issuerDID
                  ? '/community/' + communityTemplateData.issuerDID
                  : !communityTemplateData?.issuerDID &&
                    verifiableCredential.type === 'Community'
                  ? '#'
                  : '/community/' + verifiableCredential.issuer.did
              "
              class="flex items-center"
            >
              <img
                :src="
                  verifiableCredential.issuer.type === 'Issuer Profile'
                    ? verifiableCredential.issuer.profileImage.replace(
                        'https://ipfs.infura.io/ipfs/',
                        'https://myntfsid.mypinata.cloud/ipfs/'
                      )
                    : verifiableCredential.endorsement
                    ? verifiableCredential.endorsement.image
                    : require('/assets/images/logoMydid.png')
                "
                alt=""
                class="rounded-full border w-12 h-12 mr-5"
              />
              <p class="text-lg font-medium">
                {{ verifiableCredential.issuer.name }}
              </p>
            </a>
            <div v-else>
              <p class="font-medium">
                {{ verifiableCredential.issuer.did }}
              </p>
            </div>
          </div>

          <div
            v-if="
              verifiableCredential.type === 'Community' &&
              verifiableCredential.endorsement
            "
            class="flex flex-row py-5 border-b-[0.2px]"
          >
            <div
              class="flex items-center lg:space-x-2 space-x-0 w-40 text-gray-400"
            >
              <p class="px-4">Role:</p>
            </div>
            <div>
              <p>{{ verifiableCredential.endorsement.name }}</p>
            </div>
          </div>

          <div class="flex flex-col py-2 border-b-[0.2px] mb-5">
            <div
              v-if="verifiableCredential.type === 'Participation'"
              class="flex flex-col py-2 border-b-[0.2px] mb-5"
            >
              <div class="flex items-center space-x-2 text-black font-medium">
                <img
                  :src="require(`/assets/images/calendar.png`)"
                  class="w-[15px] h-[15px] max-w-none z-50"
                />
                <p class="">Event date:</p>
              </div>
              <div class="flex items-center">
                <p class="text-[15px] px-6 font-medium">
                  {{
                    new Date(
                      verifiableCredential.startDate
                    ).toLocaleDateString()
                  }}
                </p>
              </div>
            </div>

            <div
              v-if="verifiableCredential.type === 'Participation'"
              class="flex flex-col py-2 border-b-[0.2px] mb-5"
            >
              <div
                class="flex items-center space-x-2 w-40 text-black font-medium"
              >
                <img
                  :src="require(`/assets/images/calendar.png`)"
                  class="w-[15px] h-[15px] max-w-none z-50"
                />
                <p class="">Location:</p>
              </div>
              <div class="flex items-center">
                <p class="text-[15px] px-6 font-medium">
                  {{
                    verifiableCredential.location
                      ? verifiableCredential.location
                      : 'Virtual'
                  }}
                </p>
              </div>
            </div>
            <div
              v-if="verifiableCredential.description"
              class="flex items-center space-x-2 text-black font-medium"
            >
              <img
                :src="require(`/assets/images/description.png`)"
                class="w-[15px] h-[15px] max-w-none z-50"
              />
              <p class="">Description:</p>
            </div>
            <div class="flex items-center">
              <p class="pl-6">
                {{ verifiableCredential.description }}
              </p>
            </div>
          </div>
          <div
            v-if="verifiableCredential.criteria"
            class="flex flex-col py-2 mb-5"
          >
            <div class="flex items-center space-x-2 text-black font-medium">
              <img
                :src="require(`/assets/images/Criteria.png`)"
                class="w-[19px] h-[20px] max-w-none z-50"
              />
              <p class="">Criteria:</p>
            </div>
            <div class="flex items-center">
              <p class="pl-7">
                {{ verifiableCredential.criteria }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted, inject } from 'vue';
import TemplatePreview from './TemplatePreview.vue';
import TemplateRolePreview from './TemplateRolePreview.vue';
import { useRoute } from 'vue-router';
import { createToast } from 'mosha-vue-toastify';

export default {
  props: {
    vc: Object,
  },
  setup(props) {
    const verifiableCredential = ref(props.vc);
    const dynamicQrCodeSrc = ref(null);
    const utils = inject('utils');
    const communityTemplateData = ref(null);
    const communityTemplateIssuer = ref(null);
    const templateLabel = ref(
      utils.templateTypeToLabel(verifiableCredential.value.type, 'en', true)
    );
    const templateTypeToLabel = (type) => {
      return utils.templateTypeToLabel(type, 'en', true);
    };

    const route = useRoute();
    const code = ref(route.query.code);
    const isCopied = ref(false);

    if (verifiableCredential.value.type === 'Community') {
      utils
        .getTemplateData(verifiableCredential.value.raw.templateHash)
        .then((communityTemplateDataFromIpfs) => {
          communityTemplateData.value = communityTemplateDataFromIpfs;
        });
    }

    function formatDateWithRelativeTime(dateString) {
      const date = new Date(dateString);
      const now = new Date();
      const diffTime = Math.abs(now - date);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: 'UTC',
        timeZoneName: 'short',
      };
      const formattedDate = new Intl.DateTimeFormat('en-US', options).format(
        date
      );

      return `${diffDays} days ago (${formattedDate})`;
    }

    function copyCode() {
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(code.value).then(
          () => {
            createToast('Code copied to clipboard', {
              type: 'success',
              showIcon: true,
              position: 'top-right',
              toastBackgroundColor: '#050931',
            });
            isCopied.value = true;
            setTimeout(() => {
              isCopied.value = false;
            }, 500);
          },
          (err) => {
            console.error('Could not copy text: ', err);
          }
        );
      }
    }

    return {
      verifiableCredential,
      dynamicQrCodeSrc,
      templateLabel,
      formatDateWithRelativeTime,
      code,
      copyCode,
      isCopied,
      communityTemplateData,
      templateTypeToLabel,
    };
  },

  components: {
    TemplatePreview,
    TemplateRolePreview,
  },
};
</script>
