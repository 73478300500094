import { createApp } from 'vue';
import 'mosha-vue-toastify/dist/style.css';
import './styles.css';
import '@fortawesome/fontawesome-free/js/all.js';
import BounceLoader from 'vue-spinner/src/BounceLoader.vue';

import router from './router.js';
import api from './plugins/api.js';
import utils from './plugins/utils.js';
import App from './App.vue';
import TheHeader from './components/TheHeader.vue';
import VCPresentation from './components/VCPresentation.vue';
import Icon from './components/Icon.vue';
import TheFooter from '@/components/TheFooter.vue';
import QrcodeVue from 'qrcode.vue';

const app = createApp(App);

app.use(router);
app.use(api);
app.use(utils);
app.component('the-header', TheHeader);
app.component('the-footer', TheFooter);
app.component('vc-presentation', VCPresentation);
app.component('custom-loader', BounceLoader);
app.component('icon', Icon);
app.component('qrcode-vue', QrcodeVue);

app.mount('#app');
