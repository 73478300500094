<template>
  <the-header />
  <div class="flex flex-col min-h-screen">
    <router-view v-slot="slotProps">
      <component :is="slotProps.Component"></component>
    </router-view>
  </div>
  <div class="flex flex-col">
    <the-footer></the-footer>
  </div>
</template>
