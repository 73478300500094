<template>
  <div class="bg-white min-h-screen">
    <div v-if="!loading" class="w-full max-w-7xl mx-auto p-4">
      <div class="flex justify-between flex-wrap items-center mb-4">
        <div class="relative">
          <input
            v-model="searchQuery"
            type="text"
            placeholder="Find communities"
            class="pl-10 pr-4 py-2 border rounded-md w-64 focus:outline-none text-sm"
          />
          <span class="absolute left-3 top-2">
            <i class="fas fa-search text-gray-400"></i>
          </span>
        </div>
        <div class="flex items-center gap-2">
          <div class="text-sm text-gray-600">Order by</div>
          <select
            v-model="sortAscending"
            class="border rounded-md py-1 px-2 text-sm"
          >
            <option :value="true">Oldest first</option>
            <option :value="false">Newest first</option>
          </select>
        </div>
      </div>

      <div class="grid grid-cols-1 sm:grid-cols-2 my-10 md:grid-cols-3 gap-7">
        <div
          v-for="(community, index) in displayedCommunities"
          :key="index"
          class="border rounded-lg overflow-hidden shadow-sm"
        >
          <router-link :to="'/community/' + community.did">
            <img
              :src="community.templateData.image"
              :alt="community.templateData.name"
              class="w-full h-36 object-cover"
            />
            <div class="relative">
              <div
                class="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-1 rounded-full text-sm"
              >
                <img
                  :src="require('/assets/images/Certif.png')"
                  alt="Avatar"
                  class="w-6 h-6 rounded-full"
                />
              </div>
            </div>
            <div class="p-5">
              <div class="flex items-center justify-between">
                <div class="flex items-center space-x-2">
                  <img
                    :src="community.issuerProfile.profileImage"
                    alt="Avatar"
                    class="w-8 h-8 rounded-full"
                  />
                  <span class="font-medium text-[18px]">{{
                    community.issuerProfile.name
                  }}</span>
                </div>
                <span class="text-sm text-black text-[22px]">{{
                  new Date(community.createdDate).getFullYear()
                }}</span>
              </div>
              <div class="flex items-center justify-between mt-1">
                <span class="text-[18px] font-semibold ml-10">{{
                  community.issuerProfile.sector
                }}</span>
                <div class="flex items-center space-x-1">
                  <icon name="member-count" class="w-4 h-4"></icon>
                  <span class="text-sm text-black text-[15px]">{{
                    community.members
                  }}</span>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="flex justify-center mt-6">
        <button
          v-if="communities && currentPage * itemsPerPage < communities.length"
          @click="currentPage++"
          class="bg-indigo-600 text-white px-4 py-2 rounded-md"
        >
          Load more
        </button>
      </div>
    </div>
    <div v-else class="flex justify-center items-center gap-3 mt-10">
      <custom-loader class="" color="#D0C4E7" size="50px"></custom-loader>
      <p class="text-lg text-gray-400">Loading...</p>
    </div>
  </div>
</template>

<script>
import { ref, inject, computed } from 'vue';

export default {
  setup() {
    const api = inject('api');
    const utils = inject('utils');
    const communities = ref([]);
    const loading = ref(true);
    const searchQuery = ref('');
    const sortAscending = ref(false);
    const itemsPerPage = ref(6);
    const currentPage = ref(1);

    const displayedCommunities = computed(() => {
      let communitiesToDisplay = [...communities.value];
      communitiesToDisplay = communitiesToDisplay.slice(
        0,
        itemsPerPage.value * currentPage.value
      );

      // sort communities by createdDate
      communitiesToDisplay.sort((a, b) => {
        return sortAscending.value
          ? new Date(a.createdDate) - new Date(b.createdDate)
          : new Date(b.createdDate) - new Date(a.createdDate);
      });

      // filter communities by searchQuery
      if (searchQuery.value && searchQuery.value.length > 1) {
        communitiesToDisplay = communitiesToDisplay.filter(
          (community) =>
            community.issuerProfile.name
              .toLowerCase()
              .includes(searchQuery.value.toLowerCase()) ||
            community.issuerProfile.sector
              .toLowerCase()
              .includes(searchQuery.value.toLowerCase())
        );
      }

      return communitiesToDisplay;
    });

    async function fetchIssuerDetails(did) {
      let issuerProfile;
      let memberCount;
      let oldestTemplateData;
      let templateData;

      await Promise.all([
        new Promise(async (resolve, reject) => {
          let profileIpfsUrl;
          try {
            const { data } = await api.getIssuerDetailsLinkFromCode(did);
            profileIpfsUrl = data.service[0]?.serviceEndpoint;
          } catch (e) {
            reject(`Error fetching did document for DID ${did}:`, e);
          }
          if (!profileIpfsUrl) {
            console.log('No profile on IPFS for issuer :', did);
            resolve();
          }

          try {
            issuerProfile = await utils.getJsonDataFromUrl(profileIpfsUrl);
          } catch (e) {
            reject(`Error fetching profile for DID ${did}:`, e);
          }

          resolve();
        }),
        new Promise(async (resolve, reject) => {
          let templateList;
          const addr = utils.didToAddress(did);
          try {
            templateList = await utils.getTemplateList(addr, 'Membership');
          } catch (e) {
            reject(`Error retrieving templates for DID ${did}:`, e);
          }

          const membercardTemplateHash = templateList[0].templateHash;
          try {
            [templateData, oldestTemplateData] = await Promise.all([
              utils.getTemplateData(membercardTemplateHash),

              utils
                .getOldestTemplateHash(membercardTemplateHash)
                .then(utils.getTemplateData),
            ]);
            try {
              memberCount = await api.getCommunityMemberCount(
                membercardTemplateHash.split('0x')[1]
              );
              memberCount = memberCount.data.members.number;
            } catch (e) {
              memberCount = -1;
            }
          } catch (e) {
            reject(`Error retrieving membercard data for ${did}:`, e);
          }

          resolve();
        }),
      ]).catch((e) => {
        console.error('Error fetching issuer details:', e);
        throw e;
      });

      return {
        did,
        issuerProfile,
        members: memberCount,
        createdDate: oldestTemplateData.created,
        templateData,
      };
    }

    async function fetchAllIssuersDetails() {
      loading.value = true;

      const issuersDids = (await api.getFeaturedCommunities()).data;
      communities.value = (
        await Promise.all(issuersDids.map(fetchIssuerDetails))
      ).filter((issuer) => issuer.issuerProfile);

      loading.value = false;
    }
    fetchAllIssuersDetails();

    return {
      communities,
      displayedCommunities,
      loading,
      searchQuery,
      sortAscending,
      currentPage,
      itemsPerPage,
    };
  },
};
</script>
