<template>
  <div
    class="flex items-center w-fit border border-secondary bg-white gap-1 py-1 pl-2 pr-3 rounded-full"
    :class="{
      'border-4': enableSelection && selected,
      '!border-gray-200 border-4': enableSelection && !selected,
    }"
  >
    <div
      class="w-4 h-4 inline-flex rounded-full"
      :style="`background-color:${color}`"
    ></div>
    <img :src="emoji" class="w-5 mr-0.5" />
    <p
      class="text-sm font-medium max-w-[7rem] min-w-[2rem] line-clamp-1 break-all"
    >
      {{ name }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    emoji: String,
    color: String,
    enableSelection: Boolean,
    selected: Boolean,
  },
  setup() {},
};
</script>
