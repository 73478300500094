<template>
  <div
    class="w-full 2xl:max-w-[70%] mx-auto border-gray-100 border-b-[0.2px] pb-5 pt-10 mb-16"
  >
    <div
      class="flex flex-col md:flex-row items-center justify-between bg-white mx-5"
    >
      <router-link
        to="/welcome"
        class="flex items-center space-x-2 mb-4 md:mb-0"
      >
        <div
          class="flex items-center justify-center rounded-full w-12 h-12 md:w-16 md:h-16"
        >
          <img
            :src="require('/assets/images/logo_comect.png')"
            alt="Logo"
            class="w-12 h-12 md:w-16 md:h-16 rounded-full mx-auto"
          />
        </div>
        <div class="flex space-y-1 flex-col">
          <p class="text-[30px] font-semibold text-[#050931]">myDidCheck</p>
          <p class="text-[16px] font-semibold text-[#050931]">Product of COMECT</p>
        </div>
      </router-link>
      <div class="flex-row items-center space-x-2">
        <div class="relative w-full md:w-auto">
          <input
            type="text"
            @keyup.enter="search"
            v-model="searchTerm"
            placeholder="Search by Badge Link / Community DID"
            class="border-[1px] border-gray-300 rounded-[8px] py-1 pl-10 pr-5 w-full bg-[#F7F8F9] md:w-[528px] focus:outline-none"
          />
          <button
            @click="search"
            class="absolute left-0 top-0 bottom-0 px-2 rounded-l-lg border-b border-t border-l border-gray-300"
          >
            <icon name="searchIcon" class="w-[20px] h-20px" />
          </button>
        </div>
      </div>
    </div>

    <div class="px-0 mt-8 bg-white mx-5">
      <h2 class="text-[21px] font-semibold text-[#050931]">
        {{ pageName }}
      </h2>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';

export default {
  setup() {
    const searchTerm = ref('');
    const router = useRouter();
    const route = useRoute();
    const pageName = computed(() => route.name);

    const search = () => {
      const trimmedTerm = searchTerm.value.trim();
      // if trimedTerm starts with did:mydid: then search by DID redirect to /community/trimedTerm
      if (trimmedTerm.startsWith('did:mydid:')) {
        const url = `/community/${trimmedTerm}`;
        router.push(url);
        return;
      }
      const url = `/?code=${trimmedTerm}`;
      router.push(url);
    };

    return {
      searchTerm,
      search,
      pageName,
    };
  },
};
</script>
