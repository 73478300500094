import { createRouter, createWebHistory } from 'vue-router';
import Home from './views/Home';
import Community from './views/Community.vue';
import FeaturedCommunities from './views/FeaturedCommunities.vue';
import Welcome from './views/Welcome.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: Home, name: 'Badge details' },
    { path: '/welcome', redirect: '/communities' },
    {
      path: '/community/:id',
      component: Community,
      props: true,
      name: 'Community',
    },
    {
      path: '/communities',
      component: FeaturedCommunities,
      name: 'Communities',
    },
    { path: '/:notFound(.*)', redirect: '/communities' },
  ],
});

export default router;
